/* eslint-disable no-nested-ternary */
// const prod = process.env.NODE_ENV === 'production';

const live = process.env?.REACT_APP_DEV_LIVE === 'live';
const staging = process.env?.REACT_APP_DEV_LIVE === 'staging';

const local = 'http://192.168.0.148:9000';
const baseUrl = live
  ? 'https://api.footbizz.in'
  : staging
    ? 'https://staging-api.footbizz.in'
    : local;

export default {
  siteName: 'React',
  siteIcon: 'ion-flash',
  footerText: `© ${new Date().getFullYear()} test`,

  apiUrl: baseUrl,
  csvUrl: 'https://192.168.0.148/SampleCsv',
  sailsUrl: 'https://192.168.0.148/',

  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  tinyEditorKey: 'zbs5etcfyasms34yp3h0spt1h722agxsaejl5ph5ftdkiziu',
  dashboard: '/dashboard',
};
