import { createGlobalStyle } from 'styled-components';
import { palette, font } from 'styled-theme';
// import 'antd/dist/antd.css';

const GlobalStyles = createGlobalStyle`
  /* roboto-300 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/roboto/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Roboto Light'), local('Roboto-Light'),
        url('/fonts/roboto/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/roboto/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/roboto/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
        url('/fonts/roboto/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/roboto/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-regular - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/roboto/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto'), local('Roboto-Regular'),
        url('/fonts/roboto/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/roboto/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/roboto/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
        url('/fonts/roboto/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/roboto/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-regular - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/roboto/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto'), local('Roboto-Regular'),
        url('/fonts/roboto/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/roboto/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/roboto/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
        url('/fonts/roboto/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/roboto/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-700 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/roboto/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url('/fonts/roboto/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/roboto/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/roboto/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
        url('/fonts/roboto/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/roboto/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  .for-link-view a span.anticon.anticon-link, .for-link-view a span.anticon.anticon-eye, .for-link-view a span.anticon.anticon-pound, .for-link-view a span.anticon.anticon-key{
    margin-top:8px
  }
  .isoInvoiceTable table tbody tr td .isoInvoiceBtnView.m-5 > a,
  .isoInvoiceTable table tbody tr td .isoInvoiceBtnView.m-5 > button{
    margin: 0 2px 0 0 !important;
  }
  /* roboto-900 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('/fonts/roboto/roboto-v20-latin-900.eot'); /* IE9 Compat Modes */
    src: local('Roboto Black'), local('Roboto-Black'),
        url('/fonts/roboto/roboto-v20-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/roboto/roboto-v20-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/roboto/roboto-v20-latin-900.woff') format('woff'), /* Modern Browsers */
        url('/fonts/roboto/roboto-v20-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/roboto/roboto-v20-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft{
    z-index:999;
  }
  .btnContainer{
    margin-bottom:10px
  }
  * {
    outline: 0 !important;
  }
  .btnContent{
     margin-right :10px
  }

  :where(.css-dev-only-do-not-override-nllxry).ant-table-wrapper .ant-table-cell-ellipsis{
    overflow: visible !important;
  }
  :where(.css-dev-only-do-not-override-nllxry).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
    border-color: red !important;
  }

  .chartTable
  {
    .ant-table-thead tr th {
        background-color: #fff !important;
    }
    .ant-pagination{
      margin: 16px !important;
    } 
  }

 
  .ant_dot_color .ant-spin-dot {
  color: red !important; /* Change color to red */
}

  .MoreInfo{
    width: 950px !important;

    .modalContainer{
      display: flex;
      flex-direction: column;
       gap: 10px;
       padding: 10px;
      border-radius: 10px;
    }
    .modalMain{
        font-weight: bold; 
        font-size: 16px;
        color: rgb(26, 40, 108);
        text-transform: capitalize;
        }
    .image-all{
        display: flex;
        width: 100%;
        align-items: center;
    }
    .mainVideoConterner{
      display: flex;
      gap: 5px;
    }
    .setvideo{
      display: flex;
      flex-direction: column;
    }
    .forImageGap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row; 
    }
    .comman-img{
      margin: 10px 10px 10px 10px;
    }   
    .videoimgSet{
      margin: 10px 10px 10px 10px;
      cursor: pointer;
      
    } 
    .videoimgSet img{
      border: 1px solid gray;
      border-radius: 5px;
      object-fit: contain;
    padding: 30px;
    transition: 0.5s ease-in-out;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    }
    }
    .comman-img img{
      border: 1px solid gray;
      border-radius: 5px;
    }
    .hour-category{
      display: flex;
     flex-direction: row;
     gap:10px;
    }
   
    @media only screen and (max-width: 576px) {
      .hour-category {
        display: flex;
        flex-direction: column;
        gap:10px;
      }
      
     }
    .tab-hour{
      display: flex;
      flex-direction: column;
      width: 50%;
      }

      .tab-hour3{
        width: 32.5%;
      }
      .tab-hour4{
        width: 65%;
      }
    
    .tab-hour2{
      display: flex;
      flex-direction: column;
      width: 50%;
    }
    .hourLabel{
      font-weight: bold;
      font-size: 16px;
      color: rgb(26, 40, 108);
      margin-bottom: 10px;
    }
    .pricelabel{
      font-weight: bold;
      font-size: 16px;
      color: rgb(26, 40, 108);
    }
    .hours{
       display: flex;
       flex-direction: column;  
       gap: 10px;
    }
    .day-all{
      display:flex;
      gap:10px;
      align-items: center;
    }
    @media only screen and (max-width: 576px){
      .day-all{
        margin:auto;
        display:flex;
      }
    }
   
   .certificationTitle
   {
    font-weight: bold;
    width: 50px;
   }
   .certificationTitle2
   {
    font-weight: bold;
    width: 90px;
   }

   .Dotset
   {
    display: flex;
   }
   .Dotsetpadding
   {
    padding-left: 5px;
   }

   .urlBtn
   {
    background-color: #1e38bb;
    border-color: #d9d9d9;
    width: 150px;
    color: #fff;
   }

   :where(.css-dev-only-do-not-override-nllxry).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
    color: #fff !important;
   }

   
    .all-time{
      border: 1px solid gray;
    padding: 4px 6px;
    border-radius: 5px;
    }
    .categoryLabel {     
       font-weight: bold;
       font-size: 16px;
       color: rgb(26, 40, 108);
       margin-bottom: 10px;
    }
    .categoryType{
       display: flex;
       gap: 4px;
       align-items: baseline;
       flex-wrap: wrap;
    }
    .category-all{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
    .category{
      border: 1px solid gray;
    padding: 4px 6px;
    border-radius: 5px;
    }
    .summaryLabel{
        font-weight: bold;
        font-size: 16px;
        color: rgb(26, 40, 108);
    }
    .summary{
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .summaryDetails{
      display: flex;
      gap: 10px;
      fontSize: 13px;
      }
  }
  .break-word{
    word-break: break-all
  }
  span.anticon.anticon-camera{
    margin:16px !important
  }
  .label-font{
    font-size:16px;
    font-weight:700
  }
  .viewChatLink {
    fontSize: 14px;
    color: ${palette('primary', 0)} !important;
  }

  .linkSecondary {
    color: ${palette('text', 3)};
  }

  .smallIconImg { 
    width: 18px;
    height: 18px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }





  /*-----------------------------------------------*/ 
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-family: 'Roboto';
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;
      
      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }

  .pedingDropSet
{
 
  :where(.css-xu9wm8).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item, :where(.css-xu9wm8).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item.ant-btn-sm, :where(.css-xu9wm8).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item.ant-btn-lg{
    background-color: #da9a1be8;
    color: #fff;
  }
  :where(.css-dev-only-do-not-override-nllxry).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item, :where(.css-dev-only-do-not-override-nllxry).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item.ant-btn-sm, :where(.css-dev-only-do-not-override-nllxry).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item.ant-btn-lg{
    background-color: #da9a1be8;
    color: #fff;
  }
}

.acceptDropSet
{
  
  :where(.css-xu9wm8).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item, :where(.css-xu9wm8).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item.ant-btn-sm, :where(.css-xu9wm8).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item.ant-btn-lg{
    background-color: #204331;
    color: #fff;
  }
  :where(.css-dev-only-do-not-override-nllxry).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item, :where(.css-dev-only-do-not-override-nllxry).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item.ant-btn-sm, :where(.css-dev-only-do-not-override-nllxry).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item.ant-btn-lg{
    background-color: #204331;
    color: #fff;
  }
}

.rejectDropSet
{
  :where(.css-xu9wm8).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item, :where(.css-xu9wm8).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item.ant-btn-sm, :where(.css-xu9wm8).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item.ant-btn-lg{
    background-color: #ba0005f2;
    color: #fff;
  }
  :where(.css-dev-only-do-not-override-nllxry).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item, :where(.css-dev-only-do-not-override-nllxry).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item.ant-btn-sm, :where(.css-dev-only-do-not-override-nllxry).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item.ant-btn-lg{
    background-color: #ba0005f2;
    color: #fff;
  }
  
}

.sizeSet
{
  :where(.css-dev-only-do-not-override-nllxry).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item, :where(.css-dev-only-do-not-override-nllxry).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item.ant-btn-sm, :where(.css-dev-only-do-not-override-nllxry).ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-first-item.ant-btn-lg{
    width: 115px;
  }
  
}
:where(.css-dev-only-do-not-override-nllxry).ant-dropdown .ant-dropdown-menu, :where(.css-dev-only-do-not-override-nllxry).ant-dropdown-menu-submenu .ant-dropdown-menu{
    width: 130px;
  }

.eseIconSet{
  display: flex;
    justify-content: center;
    align-items: center;
    color: black;
}

:where(.css-dev-only-do-not-override-11xg00t).ant-btn-primary{
  background-color: rgb(214, 0, 46) !important;
}

  /*-----------------------------------------------*/ 
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }

/*-----------------------------------------------*/ 
// style for React Multiple Date Selector
/*-----------------------------------------------*/
.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: unset;

  & div.day {
    background-color: #f0f0f0;
    opacity: 0.8;
  }
}

.DayPicker {
  line-height: 1.2;
  .DayPicker-WeekdaysRow {
    border-bottom: 1px solid #f0f0f0;
  }

  & .DayPicker-Day {
    border-radius: 2px;
    padding: 0.4em;
    position: relative;

    & .day {
      padding: 0.4em;
      border-radius: 50%;

      &.choosed {
        font-weight: bold;
  
        & span.dot {
          position: absolute;
          padding: 2.5px;
          border-radius: 50%;
          background: ${palette('primary', 0)};
          bottom: -2px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  // & .DayPicker-Day--today {
  //   color: ${palette('primary', 0)};
  // }

  & .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: unset;
    & div.day {
      background-color: ${palette('primary', 0)};
      opacity: 0.8;
    }
  }
  
  & .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: unset;

    & .day {
      color: #eee;
      background-color: ${palette('primary', 0)};
    }
  }
}



/*-----------------------------------------------*/ 
  // Checkbox Style
/*-----------------------------------------------*/

.inlineLabel {
  & > span {
    display: inline-block;
  }
}



/*-----------------------------------------------*/ 
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #f8f8f8 !important;
  @media only screen and (max-width: 768px) {
    background: transparent !important;
  }
}

font-family: ${font('primary', 0)};

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
strong,
footer,
svg {
  &::selection {
    background: ${palette('primary', 0)};
    color: #fff;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  &.remForgotPssd {
    margin-top: 10px !important;
    justify-content: flex-end;
  }
}

.isoRightComponent {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/********** Add Your Global CSS Here **********/

body {
  -webkit-overflow-scrolling: touch;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/* Instagram Modal */

.ant-modal-wrap.instagram-modal .ant-modal {
  max-width: 935px;
  width: 100% !important;
}

@media only screen and (max-width: 991px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    padding: 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    max-width: 580px;
  }
}

.ant-modal-wrap.instagram-modal .ant-modal-content {
  border-radius: 0;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close {
  position: fixed;
  color: #fff;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close i {
  font-size: 24px;
}

.ant-modal-wrap.instagram-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}

/********** Add Your Global RTL CSS Here **********/

/* Popover */

html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */

html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */

html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */

html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */

html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */

html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */

html[dir='rtl'] .dz-hidden-input {
  display: none;
}

/* Custom global styles */

/* GENERAL */
.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px;
}
.mt15 {
  margin-top: 15px;
}
.mt25 {
  margin-top: 25px;
}
.ml5 {
  margin-left: 5px;
}

.mr5 {
  margin-right: 5px;
}
.rowSpaceBetween { 
  display: flex;
  justify-content: space-between;
}

.avatar-contain {
  .ant-avatar > img {
    object-fit: contain;
  }
}

td.ant-table-cell.actionCell {
  padding-left: 10px;
}

.filterMain {

  & > div {
    margin-bottom: 10px;
  }

  .filterWrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    & > div {
      margin-right: 10px;
      margin-bottom: 10px;

      & .ant-select {
        min-width: 220px;
        width: 100%;
      }
    }
  }

  .btnsMain {
    & button {
      margin-left: 5px;
    }
  }

  @media (max-width: 1220px) {
    flex-direction: column-reverse !important;

    .filterWrapper {
      margin-top: 15px;

      & > div {
        margin-bottom: 10px;
      }
    }

    .ant-btn-icon-only {
      padding: 0px !important;
      min-width: 45px;
    }
  
    .btnsMain { 
      width: 100%;
      flex-direction: row-reverse;

    }
  }

  .ant-btn-icon-only {
   &:hover {
    border-color: ${palette('secondary', 11)} !important;
    box-shadow: 0 0 0 2px ${palette('secondary', 12)} !important;
    span{
        color: ${palette('secondary', 11)} !important;
    }
   }
   &:focus {
    border-color: ${palette('secondary', 11)} !important;
    box-shadow: 0 0 0 2px ${palette('secondary', 12)} !important;
    span{
        color: ${palette('secondary', 11)} !important;
    }
   }
    span{
        color: ${palette('primary', 1)} !important;
    }
  }

  @media (max-width: 768px) {
    .filterWrapper {
      flex-direction: column;
      flex: unset;
      & > div {
        margin-right: 0px;
      }
    }
  }
}


/* INPUT */

.ant-input:focus, .ant-input:hover, .ant-input-number:hover , .ant-input-number:focus, .ant-input-number-focused {
  border-color: ${palette('secondary', 11)} !important;
  box-shadow: 0 0 0 2px ${palette('secondary', 12)} !important;
}

.ant-input-password:focus, .ant-input-password:hover {
  border-color: ${palette('secondary', 11)} !important;
  box-shadow: 0 0 0 2px ${palette('secondary', 12)} !important;
}


.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: ${palette('secondary', 11)} !important;
  box-shadow: 0 0 0 2px ${palette('secondary', 12)} !important;
}

.ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:hover {
  border-color: ${palette('secondary', 11)} !important;
  box-shadow: 0 0 0 2px ${palette('secondary', 12)} !important;
}
.ant-otp{
  display: flex;
  justify-content: space-between;
}
.ant-otp-input{
  width: 50px;
  height: 50px;
  font-size: 20px !important;
}

/* TEXTAREA */

& .global-textArea-count-style {
    &.ant-input-textarea-show-count::after {
      position: relative;
      top: -31px;
      right: 16px;
      font-size: 12px;
      color: #a0a0a0;
      font-weight: 400;
    }
    & textarea {
      border: 1px solid #e2e2e2;
      padding: 12px 16px 32px;
    }
  }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}

/* FORM ITEM */
.ant-form {
  & .ant-row.ant-form-item {
    margin-bottom: 10px;
  }
  & .ant-row.form-item-country {
    margin-bottom: 0px;
    & .ant-form-item-control-input {
      min-height: unset;
    }
  }
}


/* DRAWER  */
.ant-drawer-content-wrapper {
  max-width: 100%;
  overflow: auto;

  & .ant-drawer-body {
    // padding-bottom: 80px;
    // @media (max-width: 768px) {
    //   padding-bottom: 140px !important;
    // }

    & .bottomBtnWrapper {
      z-index: 999;
      & > div {
        margin-bottom: 0px;
      }
      // @media (max-width: 768px) {
      //   padding-bottom: 67px !important;
      // }
    }
  }
}

.ant-menu.langMenu {
  border-right: 0px;
  & .ant-menu-item {
    // height: 30px;
    // padding: 0;
    min-width: 80px;
  }
}



/* POPOVER CSS */
.ant-popover-placement-bottomLeft {
  & .ant-popover-arrow {
    @media (max-width: 425px) {
      // display: none;
    }
  }
}

@media (max-width: 425px) {
  td.ant-descriptions-item-content{
    word-break: break-all
  }
}

& .radioLayout {
  display: flex !important;
  margin-top: 10px;
  & label{
    display: flex;
    align-items: center;
    & .ant-radio{
      height: max-content;
    }
  }
}

& .managerNameClass {
  overflow: visible !important;
}

& .aminitiesCaurosul {
  & .slick-list {
    display: grid !important;
    & .slick-track {
      & .slick-slide {
        display: grid;
        & > div {
          display: grid;
          height: inherit;
        }
      }
    }
  }
}
& .memberShipCategoryTable > div > div > div > div > div > table > tbody > tr{
  cursor: pointer !important;
}

& .space-class{
  width: 100% !important;
  & .ant-space-item {
    width: 100% !important;
  }
}

& .featureCategory {
  padding: 0 8px 4px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  & .ant-space-item:nth-child(1) {
    width: 100%;
  }
}
& .link_btn{
  display: flex;
  color: ${palette('primary', 8)};
  &:hover{
    color: ${palette('primary', 0)};
  }
}
& .ant-select-selector{
  &:hover, &:focus {
    border-color: ${palette('secondary', 11)} !important;
    box-shadow: 0 0 0 2px ${palette('secondary', 12)} !important;
  }
}
& .ant-select-focused {
  & .ant-select-selector{
    border-color: ${palette('secondary', 11)} !important;
    box-shadow: 0 0 0 2px ${palette('secondary', 12)} !important;
    & span {
      color: ${palette('secondary', 10)} !important;
    }
  }
}

& .ratingDropdownDiv{
  & .ant-select-selector{
    & span {
      color: unset !important;
    }
  }
}

& .ant-picker:hover, .ant-picker-focused, .ant-table-row-expand-icon:hover {
  border-color: ${palette('secondary', 11)} !important;
    box-shadow: 0 0 0 2px ${palette('secondary', 12)} !important;
    & .ant-picker-active-bar{
      background-color: ${palette('secondary', 11)} !important;
    }
}

& .ant-table-row-expand-icon:hover {
  border-color: ${palette('secondary', 11)} !important;
  color: ${palette('secondary', 11)} !important;
}

& .ant-select-item-option-selected{
  background-color: ${palette('secondary', 11)} !important;
}

& .ant-popconfirm-buttons {
  & .ant-btn-default {
    &:hover{
      color: ${palette('primary', 0)} !important;
      border-color: ${palette('primary', 0)} !important;
    }
  }
  & .ant-btn-primary {
      background-color: ${palette('primary', 0)} !important;
      border-color: ${palette('primary', 0)} !important;
  }
}

& .ant-upload-drag-icon{
  span{
    color: ${palette('text', 0)} !important;
  }
}

& .ant-upload {
  &:hover{
    border-color: ${palette('primary', 0)} !important;
    box-shadow: 0 0 0 2px ${palette('secondary', 12)} !important;
    & .ant-upload-drag-icon{
      span{
        color: ${palette('primary', 0)} !important;
      }
    }
  }
}
& .ant-modal-content{
  & .ant-modal-footer{
    & .ant-btn-default {
      height: unset !important;
      &:hover {
        border-color: ${palette('primary', 0)} !important;
        color: ${palette('primary', 0)} !important;
        font-size: 16px !important;
        height: unset !important;
      }
    }

    & .ant-btn-primary {
      background-color: ${palette('primary', 0)} !important;
      border-color: ${palette('primary', 0)} !important;
      height: unset !important;
      &:hover {
        background-color: ${palette('primary', 0)} !important;
        border-color: ${palette('primary', 0)} !important;
        color: #fff !important;
        font-size: 16px !important;
        height: unset !important;
      }
    }
  }
& .ant-slider-track{
  background-color: ${palette('primary', 0)} !important;
}
  & .ant-slider-handle{
    &::after{
      box-shadow: 0 0 0 3px ${palette('primary', 0)} !important;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

& .ant-scroll-number-only-unit{
  color: #fff !important;
}

& .featureListView{
  display: flex;
    align-items: flex-start;
    justify-content: space-between;

    & .featureListMinusIcon{
      margin-left: 10px;
    }
}
& .ant-checkbox{
  & .ant-checkbox-inner:hover{
    border-color: ${palette('primary', 0)} !important;
  }
}
& .ant-checkbox-checked {
  background-color: ${palette('primary', 0)} !important;
  color: ${palette('primary', 0)} !important;
  & .ant-checkbox-inner {
    border-color: ${palette('primary', 0)} !important;
    background-color: ${palette('primary', 0)} !important;
    color: ${palette('primary', 0)} !important;
    & :hover{
      border-color: ${palette('primary', 0)} !important;
    }
  }
}

  & .ant-checkbox-disabled {
    background-color: ${palette('secondary', 3)} !important;
      color: ${palette('secondary', 3)} !important;
    & .ant-checkbox-inner {
      border-color: ${palette('secondary', 3)} !important;
      background-color: ${palette('secondary', 3)} !important;
      color: ${palette('secondary', 3)} !important;
      & :hover{
        border-color: ${palette('secondary', 3)} !important;
      }
    }
  }


& .ant-switch-checked{
  background-color: ${palette('primary', 0)} !important;
}

& .countryCodePicker {
  min-width: 60px;
  & .ant-select-dropdown {
    ${'' /* width: unset !important; */}
  }
}

`;

export default GlobalStyles;
