/* eslint-disable default-param-last */
// import config, { getCurrentLanguage } from './config';

import { getLanguage } from '@iso/lib/helpers/utility';
import actions from './actions';

const lang = getLanguage();

const initState = {
  isActivated: false,
  allWords: {},
  language: {
    lang_code: lang || 'en',
  },
};

export default function (state = initState, action) {
  switch (action.type) {
    case actions.ACTIVATE_LANG_MODAL:
      return {
        ...state,
        isActivated: !state.isActivated,
      };
    case actions.CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
    case actions.SET_ALL_WORDS:
      return {
        ...state,
        allWords: action.allWords,
      };
    default:
      return state;
  }
}
